<template>
  <b-card>

    <h3 class="mb-2">Member's Mood Board</h3>

    <b-row>
      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="No record found"
          class="position-relative"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(project_name)="data">
          <a :href="'/cms/project/edit/' + data.item.project_id" class="btn btn-link">{{data.value}}</a>
        </template>

        <template #cell(image_url)="data">
          <feather-icon icon="FileIcon" size="32" v-if="data.value.includes('.pdf')"/>
          <b-img :src="getApiFile(data.value)" fluid width="100" height="100" class="mr-2" rounded v-if="!data.value.includes('.pdf')"/>
          <a :href="'/cms/project/images/' + data.item.project_id" class="btn btn-outline-primary">Go To Project Images</a>
        </template>

      </b-table>
    </b-row>

    <Overlay :busy="busy"></Overlay>

  </b-card>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Overlay from "@/components/Overlay.vue";
import router from '@/router'
import store from "@/store"
import storeModule from "@/views/member/store"
import {email, required} from "@core/utils/validations/validations";
import {toastMessage} from "@core/utils/utils"
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from "vue-toastification/composition";
import {BAvatar, BBadge, BButton, BCard, BCardText, BCol, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BImg, BMedia, BMediaAside, BMediaBody, BRow, BTable} from "bootstrap-vue";
import {avatarText, getApiFile} from "@core/utils/filter";
import vSelect from 'vue-select'
import languageModule from "@/views/cms/language/store";
import IdColumnTable from "@/components/Table/IdColumnTable.vue";
import RemoveButtonTable from "@/components/Table/RemoveButtonTable.vue";
import useList from "@/views/member/useLikeList";
import Vue from "vue";

export default {
  components: {
    BFormTags,
    BFormInput,
    BAvatar,
    BBadge,
    BTable,
    BFormInvalidFeedback,
    BImg,
    BMediaAside,
    BCardText,
    BFormFile,
    BMediaBody,
    BMedia,
    BFormGroup,
    BCol,
    BRow,
    BCard,
    BForm,
    BButton,

    vSelect,
    Overlay,
    ValidationProvider,
    ValidationObserver,
    RemoveButtonTable,
    IdColumnTable,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_LANGUAGE_MODULE_NAME = 'store-language'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_LANGUAGE_MODULE_NAME, languageModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_LANGUAGE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const dataInfo = ref({memberId: router.currentRoute.params.id, images: null})
    const languageOptions = ref([])

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
    } = useList()

    busy.value = true
    store.dispatch('store-language/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.panel_status === 1) {
          languageOptions.value.push({label: value.name, value: value.id})
          if (value.main_language === true) {
            dataInfo.value.languageId = value.id
          }
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'member-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,

      busy,

      dataInfo,
      languageOptions,

      getApiFile,
      fetchList,
      reFetchData,
      avatarText,

    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
